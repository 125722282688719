:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.App {
  text-align: center;
  display: flex;
  background-color: #343541;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.headerText {
  font-size: 22px;
  font-weight: bold;
  font-family: emoji;
  /* border: 1px solid; */
  padding: 5px;
}

.sidemenu {
  height: 100vh;
  background-color: #202123;
  padding: 10px;
  width: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.sidemenu::-webkit-scrollbar {
  width: 15px;
}

.sidemenu::-webkit-scrollbar-track {
  background-color: #202123;
}

.sidemenu::-webkit-scrollbar-thumb {
  background-color: #202123;
  border-radius: 20px;
  border: 3px solid rgb(136 136 136);
}

.side-menu-button {
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 4rem;
  transition: ease 0.25 all;
  cursor: pointer;
}

.side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.side-menu-button span {
  padding-right: 12px;
  padding-left: 6px;
}

.project-info {
  text-align: left;
  margin: 10px;
  gap: 1rem;
  display: grid;
}

.creator {
  display: flex;
  flex-direction: column;
}

.creator a {
  color: white;
  outline: none;
  text-decoration: none;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.link-icon {
  margin-right: 10px;
  background: white;
  padding: 2px;
  border-radius: 10px;
}

.chatbox {
  flex: 1;
  background-color: #343541;
  position: relative;
  padding-bottom: 20px;
}

.chat-log::-webkit-scrollbar {
  display: none;
}

.chat-log {
  padding: 24px;
  overflow-y: auto;
  height: 82vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.chat-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.chat-submit-btn {
  cursor: pointer;
  width: 5%;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  background-color: #40414f;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
  border: none;
  outline: none;
  border: 2px solid #757575;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.avatar img {
  width: 20px;
}

.message {
  white-space: pre-wrap;
}

.chat-input-holder {
  padding: 24px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.chat-input-textarea {
  width: 90%;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  background-color: #40414f;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
  border: none;
  outline: none;
  border: 2px solid #757575;
}

#chatBotInputDivStyle {
  background-color: #ffffff;
  color: black;
  margin-right: 8%;
}

#userInputDivStyle {
  background-color: black;
  margin-left: 6%;
  color: white;
}

.dark-mode {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.white-content {
  background: #e4e4e4;
}

@media screen and (min-width: 980px) {
  .chatbox {
    margin-inline: 8rem;
  }
  .chat-input-textarea {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .sidemenu {
    width: 23% !important;
  }
  .chatbox {
    width: 77% !important;
  }
  .side-menu-button {
    white-space: nowrap;
    overflow: hidden;
  }
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
  text-align: center;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(./assets/Gif/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
  text-decoration: none;
}
.contant_box_404 {
  margin-top: -50px;
}

/*-------------------- Browser Based Page Css------------------------------------------------ */

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Remove the border on images inside links in IE 10.
     */
img {
  border-style: none;
}

/* Forms
       ========================================================================== */

/**
     * 1. Change the font styles in all browsers.
     * 2. Remove the margin in Firefox and Safari.
     */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
     * Show the overflow in IE.
     * 1. Show the overflow in Edge.
     */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * Correct the inability to style clickable types in iOS and Safari.
     */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
     * Remove the inner border and padding in Firefox.
     */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
     * Restore the focus styles unset by the previous rule.
     */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Correct the padding in Firefox.
     */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}
/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */

progress {
  vertical-align: baseline;
}
/**
     * Remove the default vertical scrollbar in IE 10+.
     */

textarea {
  overflow: auto;
}
/**
     * 1. Add the correct box sizing in IE 10.
     * 2. Remove the padding in IE 10.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
     * Remove the inner padding in Chrome and Safari on macOS.
     */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/* Interactive
       ========================================================================== */

/*
     * Add the correct display in Edge, IE 10+, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}
/* Misc
       ========================================================================== */

/**
     * Add the correct display in IE 10+.
     */
template {
  display: none;
}
/**
     * Add the correct display in IE 10.
     */

[hidden] {
  display: none;
}
